import { motion } from "framer-motion";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { IndexGalleryTile } from "./index-gallery-tile";

export interface IIndexGalleryProps {}

export function IndexGallery(props: IIndexGalleryProps) {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const listItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };
  return (
    <div className="container p-4 mx-auto mt-4">
      <div>
        <motion.ul
          // className="flex flex-col gap-2 lg:flex-row lg:justify-between"
          className="grid gap-2 lg:gap-8 xl:gap-2 lg:grid-cols-5"
          variants={container}
          initial="hidden"
          animate="show"
        >
          {/* CATERING */}
          <motion.li key="catering" variants={listItem}>
            <Link to="/catering">
              <IndexGalleryTile
                title="Catering"
                key="ponudba"
                className="flex-grow min-w-[200px] min-h-[200px] "
              >
                <StaticImage
                  src="../../images/najajon/compressed/najajon258-min.jpg"
                  alt="Slika Catering"
                  className="min-h-[400px] lg:min-h-[600px] bg-gray-200 group-hover:bg-black "
                  transformOptions={{ fit: "cover" }} // fill
                  // width={200}
                  height={600}
                />
              </IndexGalleryTile>
            </Link>
          </motion.li>
          {/* SLIKA 1 */}
          <motion.li key="slika1" variants={listItem}>
            <IndexGalleryTile className="flex-grow min-w-[200px] min-h-[200px] hidden lg:block ">
              <StaticImage
                src="../../images/najajon/compressed/najajon252-min.jpg"
                alt="Slika Hrana"
                className="min-h-[400px] lg:min-h-[600px] bg-gray-200  "
                transformOptions={{ fit: "cover" }} // fill
                // width={200}
                height={600}
              />
            </IndexGalleryTile>
          </motion.li>
          {/* PONUDBA */}
          <motion.li key="ponudba" variants={listItem}>
            <Link to="/ponudba">
              <IndexGalleryTile
                title="Ponudba"
                key="ponudba"
                className="flex-grow min-w-[200px] min-h-[200px] "
              >
                <StaticImage
                  src="../../images/najajon/compressed/najajon444-min.jpg"
                  alt="Slika Ponudba"
                  className="min-h-[400px] lg:min-h-[600px] bg-gray-200 group-hover:bg-black "
                  transformOptions={{ fit: "cover" }} // fill
                  // width={200}
                  height={600}
                />
              </IndexGalleryTile>
            </Link>
          </motion.li>
          {/* SLIKA 2 */}
          <motion.li key="slika2" variants={listItem}>
            <IndexGalleryTile className="flex-grow min-w-[200px] min-h-[200px] hidden lg:block">
              <StaticImage
                src="../../images/najajon/compressed/najajon441-min.jpg"
                alt="Slika 2"
                className="min-h-[400px] lg:min-h-[600px] max-h-[400px] bg-gray-200  "
                transformOptions={{ fit: "cover" }} // fill
                // width={200}
                height={600}
              />
            </IndexGalleryTile>
          </motion.li>
          {/* DOGODKI */}
          <motion.li key="galerija" variants={listItem}>
            <Link to="/galerija">
              <IndexGalleryTile
                title="Galerija"
                key="galerija"
                className="flex-grow min-w-[200px] min-h-[200px] "
              >
                <StaticImage
                  src="../../images/najajon/najajonKozel.jpg"
                  alt="Slika Galerija"
                  className="min-h-[400px] lg:min-h-[600px] w-full bg-gray-200 group-hover:bg-black object-cover"
                  transformOptions={{ fit: "cover" }} // fill
                  // width={200}
                  height={600}
                />
              </IndexGalleryTile>
            </Link>
          </motion.li>

          {/* TEST */}
          {/* <motion.li key="dogodkix" variants={listItem}>
            <Link to="/galerija">
              <div
                className={`flex-grow min-w-[200px] min-h-[200px] grid  group 
      ${"Galerija" ? "hover:bg-black" : ""} `}
              >
                <div
                  className={`object-cover col-start-1 row-start-1 ${
                    "Galerija" ? "group-hover:opacity-60" : ""
                  }  `}
                >
               

                  <video
                    className="min-h-[400px] lg:min-h-[600px] bg-gray-200 group-hover:bg-black object-cover"
                    autoPlay
                    loop
                    src="https://res.cloudinary.com/dxtfvcupn/video/upload/ac_none,vc_auto/v1624843629/spiric/core/YouCut_20210525_173656162_txbace.webm"
                  />
                </div>
                {"Galerija" && (
                  <div className="z-10 col-start-1 row-start-1">
                    <div className="grid h-12 p-2 bg-gray-100 bg-opacity-40 place-items-center backdrop-filter backdrop-blur">
                      <h2 className="uppercase font-raleway">{"Galerija"}</h2>
                    </div>
                  </div>
                )}
              </div>
            </Link>
          </motion.li> */}

          {/* <img
                    src="https://res.cloudinary.com/dxtfvcupn/image/upload/e_improve,w_300,h_600,c_thumb,g_auto/v1624840357/spiric/core/20210523_141218_er48f2.jpg"
                    alt="Slika Ponudba"
                    className="min-h-[400px] lg:min-h-[600px] bg-gray-200 group-hover:bg-black "
                    height={600}
                  /> */}

          {/* {tiles.map(({ imgSrc, imgAlt, title, linkTo }, i) => (
            // <Link to={linkTo}>
            <motion.li key={i} variants={listItem}>
              <IndexGalleryTile
                title={title}
                key={imgAlt}
                className="flex-grow min-w-[200px] min-h-[200px] "
              >
                <StaticImage
                  src={imgSrcx}
                  alt={imgAlt}
                  className="min-h-[400px] lg:min-h-[600px] bg-gray-200 group-hover:bg-black "
                  transformOptions={{ fit: "cover" }} // fill
                  // width={200}
                  height={600}
                />
              </IndexGalleryTile>
            </motion.li>
            // </Link>
          ))} */}
        </motion.ul>
      </div>
    </div>
  );
}
