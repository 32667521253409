import React, { Fragment, useEffect } from "react";
import Layout from "../components/layout/layout";
import { StaticImage } from "gatsby-plugin-image";

import { IndexGallery } from "../components/features/index-gallery";
import { Helmet } from "react-helmet";
import { ContactCard } from "../components/features/contact/contact-card";

export function Index() {
  /*
   * Replace the elements below with your own.
   *
   * Note: The corresponding styles are in the ./spiric.css file.
   */

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Gostilna Spirić</title>
        <link rel="canonical" href="https://www.gostilna-spiric.si" />
        <meta name="description" content="Gostilna Spirić - naslovna stran" />
      </Helmet>
      {/* <video
        className="w-[600] h-[600]"
        autoPlay
        loop
        src="https://res.cloudinary.com/dxtfvcupn/video/upload/ac_none,vc_auto/v1624843629/spiric/core/YouCut_20210525_173656162_txbace.webm"
      /> */}
      <IndexGallery />

      {/* <div className="">
        <div className="container mx-auto mt-8 sm:px-4">
          <div className="flex flex-col p-8 text-center bg-gray-100 sm:p-16 ">
            <h1 className="text-4xl font-light leading-snug text-yellow-600 font-sacramento">
              Na kratko o nas
            </h1>
          </div>
        </div>
      </div> */}

      {/* <section className="container grid grid-rows-2 overflow-hidden rounded sm:grid-rows-1 sm:grid-cols-2 ">
        <ContactCard />
        <div className="bg-gray-200 ">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10951.664024388467!2d16.1367642!3d46.6679194!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xccef5e3a68b60136!2sGostilna%20Spiri%C4%87!5e0!3m2!1ssl!2ssi!4v1619275968859!5m2!1ssl!2ssi"
            width="600"
            height="450"
            className="w-full h-full border-0"
            loading="lazy"
          ></iframe>
        </div>
      </section> */}
    </React.Fragment>
  );
}

export default Index;
