import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export interface IIndexGalleryTileProps {
  title?: string;
  imgSrc?: string;
  imgAlt?: string;
  linkTo?: string;
  children?;
  className?;
}

export function IndexGalleryTile(props: IIndexGalleryTileProps) {
  return (
    <div
      className={`${props.className} grid  group 
      ${props.title ? "hover:bg-black" : ""} `}
    >
      {/* w-[210px]  */}
      <div
        className={`object-cover col-start-1 row-start-1 ${
          props.title ? "group-hover:opacity-60" : ""
        }  `}
      >
        {/* <StaticImage
          src="../../images/index_gallery/idx_gallery_01.jpg"
          alt="Slika Ponudba"
          width={210}
          height={600}
        /> */}
        {props.children}
      </div>
      {props.title && (
        <div className="z-10 col-start-1 row-start-1">
          <div className="grid h-12 p-2 bg-gray-100 bg-opacity-40 place-items-center backdrop-filter backdrop-blur">
            <h2 className="uppercase font-raleway">{props.title}</h2>
          </div>
        </div>
      )}
    </div>
  );
}
